<template lang="pug">
Container
	h1 {{ header }}
</template>

<script lang="ts" setup>
defineComponent({
	name: 'DefaultPage',
});

const { header } = useMeta();
</script>
